import { FC, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowCircleLeft";

import { IUnitsInformation } from "../../components/UnitsInformation";
import { IFormData } from "./type";
import { InformationPair } from "../../components/InformationPair";
import { getImageSet } from "../../components/UnitsInformation/subComponents/Image";
import { Selection } from "./Selection";
import { useUnitInformation } from "../../context/UnitsInformationProvider";
import { ReservationInformation } from "./ReservationInformation";
import { useConfig } from "../../context/ConfigProvider";
import { EVENTS } from "../../constants";

interface IReservationForm {
  selectedUnit: IUnitsInformation;
  onBackClick: () => void;
}

export const ReservationForm: FC<IReservationForm> = (props) => {
  const {
    reserve: makeReservation,
    reservation,
    reservationRequest,
    navigateToOMI,
    clearReservation,
  } = useUnitInformation();
  const [selectedTier, setSelectedTier] = useState<string>("");
  const { triggerEvent } = useConfig();

  const selectTier = (tierId: string) => {
    triggerEvent(EVENTS.ON_UNITTIER_SELECT, { unitTierId: tierId });
    setSelectedTier(tierId);
  };

  const typeOfForm = useMemo(() => {
    switch (true) {
      case props.selectedUnit.availbleUnits <= 1:
        return "contactUs";
      default:
        return "reservation";
    }
  }, [props.selectedUnit]);

  const imageSet = getImageSet(props.selectedUnit);

  const reserve = (data: IFormData, navigate: boolean = true) => {
    makeReservation(data, navigate, {
      unitGroupId: props.selectedUnit.id,
      typeOfForm,
      unitTierId: selectedTier,
    });
  };

  return (
    <div>
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={props.onBackClick}>
          <ArrowBackIcon />
          Back
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h5" gutterBottom>
            {props.selectedUnit.name ||
              `${props.selectedUnit.size.length}’ x ${props.selectedUnit.size.width}’`}
          </Typography>
          <InformationPair
            data={[
              {
                label: "Size",
                value: `${props.selectedUnit.size.width}’ x ${props.selectedUnit.size.length}’`,
              },
              {
                label: "Amenities",
                value: props.selectedUnit.amenities.join(","),
              },
              {
                label: "Price",
                value: `${props.selectedUnit.currencySymbol}${props.selectedUnit.startPrice}/${props.selectedUnit.pricePer}`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {imageSet.map((image, index) => (
              <Box
                key={index}
                sx={(theme) => ({
                  height: theme.spacing(16),
                  width: theme.spacing(16),
                })}
              >
                <img
                  src={image.url || "https://placehold.co/150"}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  alt={image.altName || "Unit Group Image"}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      {reservation && reservationRequest ? (
        <ReservationInformation
          typeOfForm={typeOfForm}
          reservationInformation={reservation}
          reservationRequest={reservationRequest}
          onMoveInClick={() => navigateToOMI(reservation)}
          onFindAnotherUnitClick={() => {
            props.onBackClick();
            clearReservation();
          }}
        />
      ) : (
        <Selection
          formType={typeOfForm}
          selectedUnit={props.selectedUnit}
          selectedTier={selectedTier}
          onSelectTier={selectTier}
          onFormSubmit={(information) => {
            console.log(information);
            reserve(information, true);
          }}
          onFormSecondarySubmit={(information) => {
            console.log(information);
            reserve(information, false);
          }}
        />
      )}
    </div>
  );
};
