import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Widget } from "./Widget";
import { ConfigProvider } from "./context/ConfigProvider";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";

// const widgetDivs = document.querySelectorAll(".storable-webapps-unitstable");

// widgetDivs.forEach((div: Element) => {
//   ReactDOM.createRoot(div).render(
//     <StrictMode>
//       <Widget {...div.dataset} />
//     </StrictMode>
//   );
// });

const renderElement = document.getElementById("storable-webapps-unitstable")!;
ReactDOM.createRoot(renderElement).render(
  <StrictMode>
    <ConfigProvider
      {...renderElement.dataset}
      onEvent={(ev) => {
        window.dispatchEvent(ev);
        
      }}
    >
      <Widget />
    </ConfigProvider>
  </StrictMode>
);
