import { FC } from 'react';
import Typography from '@mui/material/Typography';

import { UnitTierInformation } from '../../components/UnitTierInformation';
import { IUnitsInformation } from '../../components/UnitsInformation';
import { Forms } from './Forms';
import { IFormData, IReservationForm } from './type';

interface ISelection {
  selectedUnit?: IUnitsInformation; // Make this optional to handle missing data
  selectedTier?: string;
  onSelectTier: (tierId: string) => void;
  formType: IReservationForm['typeOfForm'];
  onFormSubmit: (formData: IFormData) => void;
  onFormSecondarySubmit: (formData: IFormData) => void;
}

export const Selection: FC<ISelection> = (props) => {
  const {
    selectedUnit,
    selectedTier,
    onSelectTier,
    formType,
    onFormSubmit,
    onFormSecondarySubmit,
  } = props;

  if (!selectedUnit) {
    return (
      <Typography variant='h6' color='error'>
        No unit selected. Please choose a unit to proceed.
      </Typography>
    );
  }

  return (
    <>
      {selectedUnit.tiers
        ?.filter((tier) => tier.availableUnitsCount >= 1) // Safely access `tiers`
        .map((tier) => (
          <UnitTierInformation
            key={tier.id}
            tierInfo={tier}
            selected={selectedTier === tier.id}
            onSelect={() => onSelectTier(tier.id)}
          />
        ))}
      <Typography variant="h6" gutterBottom>
        {props.formType === "contactUs"
          && "Send a message"}
      </Typography>
      <Forms
        typeOfForm={formType}
        formData={{} as IFormData}
        selectedUnit={selectedUnit}
        onFormDataSubmit={onFormSubmit}
        onFormDataSecondarySubmit={onFormSecondarySubmit}
      />
    </>
  );
};
