export const prepareUrl = (
  url: string,
  params: Array<[string, string]>,
  mode: "paramter" | "query" = "query"
) => {
  switch (mode) {
    case "query":
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return [url, ...params.map(([_key, value]) => value)].join("/");
    case "paramter":
      return (
        url + "?" + params.map(([key, value]) => `${key}=${value}`).join("&")
      );
  }
};
