import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { IUnitsInformation } from "./type";
import { UnitsImage } from "./subComponents/Image";
import { Amenities } from "./subComponents/Amenities";
import { Pricing } from "./subComponents/Pricing";
import { useConfig } from "../../context/ConfigProvider";

/**
 * This component is responsible for rendering the mobile view of the UnitsInformation.
 */
export const UnitsInformationDesktop: FC<IUnitsInformation> = (props) => {
  const { configuration } = useConfig();
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "block",
          xl: "block",
        },
        p: 1,
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
          width: "100%",
          alignItems: "center",
        })}
      >
        <UnitsImage
          unitInformation={props}
          heightFactor={12}
          widthFactor={16}
          forceCarousel
        />
        <Box sx={{ flex: 1, mx: 1 }}>
          <Typography variant="h6" gutterBottom>
            {props.name || `${props.size.length}’ x ${props.size.width}’`}
          </Typography>
          <Amenities
            amenities={props.amenities}
            noOfAmenitiesToShow={props.noOfAmenitiesToShow}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            mr: 1,
          }}
        >
          {configuration.showUnitCount &&
            props.availbleUnits <=
              configuration.contactForReserveUnitThreshold &&
            props.availbleUnits !== 0 && (
              <Typography
                variant="caption"
                sx={(theme) => ({ color: theme.palette.secondary.main })}
              >{`${props.availbleUnits} unit available`}</Typography>
            )}
          <Pricing {...props} />
        </Box>
        <Button
          onClick={props.onSelectClick}
          variant="contained"
          sx={(theme) => ({ width: theme.spacing(20) })}
        >
          {props.availbleUnits <= configuration.contactForReserveUnitThreshold
            ? "Contact Us"
            : "Select"}
        </Button>
      </Box>
    </Box>
  );
};
