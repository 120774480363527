import { FC } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { UnitsInformationMobile } from "./Mobile";
import { UnitsInformationTablet } from "./Tablet";
import { UnitsInformationDesktop } from "./Desktop";

import { IUnitsInformation } from "./type";
import { Promotion } from "./subComponents/Promotion";

export const UnitsInformation: FC<IUnitsInformation> = (props) => {
  return (
    <Box sx={{ py: 0.5 }}>
      <Card variant="outlined" sx={{ backgroundColor: "transparent" }}>
        <Promotion {...props} />
        <UnitsInformationMobile {...props} />
        <UnitsInformationTablet {...props} />
        <UnitsInformationDesktop {...props} />
      </Card>
    </Box>
  );
};

export type { IUnitsInformation } from "./type";

// export default UnitsInformation;
