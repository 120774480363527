import { Image, UnitGroup, UnitGroupTier } from "../../types/UnitGroup";
import { getImageSet } from "./subComponents/Image";

export type IUnitImageInformation = {
  images?: Image | Image[];
  preserveImageArea?: boolean;
};

export type IUnitsPriceAvailabilityInformation = {
  startPrice: number;
  endPrice?: number;
  availbleUnits: number;
  pricePer: string;
  discountedPriceStart?: number;
  discountedPriceEnd?: number;
  currencySymbol: string;
};

export type IUnitAmenitiesInformation = {
  amenities: string[];
  noOfAmenitiesToShow?: number;
};

export interface IUnitsInformation
extends IUnitImageInformation,
IUnitsPriceAvailabilityInformation,
IUnitAmenitiesInformation {
  id:string
  name?: string;
  size: { length: number; width: number; height: number };
  tiers: UnitGroupTier[];
  discountPlans: UnitGroup["discountPlans"];
  onSelectClick?: () => void;
}

export const createUnitsGroup = (unitGroup: UnitGroup): IUnitsInformation => ({
  id: unitGroup.id,
  name: unitGroup.name,
  size: {
    length: unitGroup.length,
    width: unitGroup.width,
    height: unitGroup.height || 0,
  },
  startPrice: unitGroup.price,
  discountedPriceStart: unitGroup.discountedPrice,
  // endPrice: unitGroup.discountedPrice,
  availbleUnits: unitGroup.availableUnitsCount,
  pricePer: "mo",
  currencySymbol: "$",
  amenities: unitGroup.amenities.map((amenity) => amenity.name),
  images: getImageSet({
    size: {
      length: unitGroup.length,
      width: unitGroup.width,
      height: unitGroup.height || 0,
    },
  } as IUnitsInformation),
  tiers: unitGroup.unitGroupTiers || [],
  preserveImageArea: true,
  discountPlans: unitGroup.discountPlans || [],
});
