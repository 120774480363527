import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const MASK_FORMAT = "(000) 000-0000";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={MASK_FORMAT}
        definitions={{
          "#": /[1-9]/,
        }}
        maxLength={14}
        inputRef={ref}
        onAccept={(value) =>
          onChange({
            // target: { name: props.name, value: value.replace(/[^0-9]/g, "") },
            target: { name: props.name, value: value.replace(/[^\d\s()-]/g, "") },
          })
        }
        overwrite
      />
    );
  }
);
