export interface IUTConfiguration {
  omiUrl: string;
  omiPassMode: "paramter" | "query";
  navigateOnReserve: boolean;

  enableMoveIn: boolean;
  forceMoveIn: boolean;

  showUnitCount: boolean;
  showUnavailableUnits: boolean;
  contactForReserveUnitThreshold: number;

  reservationDisclaimerText: string;
  reservationConfirmationText: string;

  themification: {
    colors: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    fontFamily: string;
    button: {
      borderRadius: number;
      textStyle: string;
    };
  };
}

export const defaultUTConfiguration: IUTConfiguration = {
  omiUrl: "http://localhost:3000",
  omiPassMode: "query",
  navigateOnReserve: false,

  enableMoveIn: true,
  forceMoveIn: false,

  showUnitCount: true,
  showUnavailableUnits: true,
  contactForReserveUnitThreshold: 1,

  reservationDisclaimerText:
    "By clicking on the Reserve button, you agree to the terms and conditions of the property",
  reservationConfirmationText:
    "Your Reservation has been confirmed and the Id for the same is {{reservationId}}",

  themification: {
    colors: {
      primary: "#000000",
      secondary: "#000000",
      tertiary: "#000000",
    },
    fontFamily: "Montserrat",
    button: {
      borderRadius: 5,
      textStyle: "normal",
    },
  },
};
