export const SortData = <T=unknown>(
    data: T[],
    property: keyof T | ((iterator: T) => string | number),
    order: "asc" | "desc"
  ) =>
    data.sort((a, b) => {
      const aValue = typeof property === "function" ? property(a) : a[property];
      const bValue = typeof property === "function" ? property(b) : b[property];
      if (aValue < bValue) {
        return order === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });