import { FC } from "react";
import Box from "@mui/material/Box";
import { IUnitsInformation } from "../type";
import { Image } from "../../../types/UnitGroup";

const possibleUnitGroupSet = {
  under5x5: { min: 0, max: 24 },
  "5x5": { min: 25, max: 25 },
  "5x10": { min: 26, max: 50 },
  "10x10": { min: 51, max: 100 },
  "10x15": { min: 101, max: 150 },
  "10x20": { min: 151, max: 200 },
  "10x25": { min: 201, max: 250 },
  "10x30": { min: 251, max: -1 },
};
export const getImageSet = (unitInformation: IUnitsInformation): Image[] => {
  const { size } = unitInformation;
  let unitGroup = Object.entries(possibleUnitGroupSet).find(([, setData]) => {
    const { min, max } = setData;
    const area = size.length * size.width;
    return area >= min && area <= max;
  });
  if (!unitGroup) {
    unitGroup = ["10x30", { min: 251, max: -1 }];
  }
  return (unitGroup[0] === "under5x5" ? [1, 2, 3] : [1, 2, 3, 4]).map(
    (image) => ({
      url: `${
        import.meta.env.VITE_IMAGES_URL ||
        "https://unitstable-widget.webapps.dev.rnl.io"
      }/${unitGroup[0]}-${image}.png`,
      altName: `${unitGroup[0]} Unit Group Image`,
      description: `${unitGroup[0]} Unit Group Image`,
    })
  );
};

interface IUnitsImage {
  unitInformation: IUnitsInformation;
  // preserveImageArea?: boolean;
  forceCarousel?: boolean;
  heightFactor?: number;
  widthFactor?: number;
}

export const UnitsImage: FC<IUnitsImage> = ({
  unitInformation,
  heightFactor = 9,
  widthFactor = 12,
  // forceCarousel = false,
}) => {
  const { images, preserveImageArea } = unitInformation;
  const finalImages = Array.isArray(images) ? images : [images];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      {finalImages && finalImages.length > 0 ? (
        <Box
          sx={(theme) => ({
            height: theme.spacing(heightFactor),
            width: theme.spacing(widthFactor),
          })}
        >
          <img
            src={finalImages[0]?.url || "https://placehold.co/150"}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            alt={finalImages[0]?.altName || "Unit Group Image"}
          />
        </Box>
      ) : preserveImageArea ? (
        <Box
          sx={(theme) => ({
            height: theme.spacing(heightFactor),
            width: theme.spacing(widthFactor),
          })}
        />
      ) : null}
    </Box>
  );
};
