import axios from "axios";
const BASE_URL = import.meta.env.VITE_API_URL || "https://unitstable-api.webapps.dev.rnl.io";
const API_KEY = import.meta.env.VITE_API_KEY || "test";
const API_SECRET = import.meta.env.VITE_API_SECRET || "test";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + btoa([API_KEY, API_SECRET].join(":")),
  },
});

export const Get = <T = unknown>(url: string): Promise<T> =>
  axiosInstance.get<T>(url).then((resp) => resp.data);

export const Post = <T = unknown, D = unknown>(
  url: string,
  data: D
): Promise<T> => axiosInstance.post<T>(url, data).then((resp) => resp.data);

export const Put = <T = unknown, D = unknown>(
  url: string,
  data: D
): Promise<T> => axiosInstance.put<T>(url, data).then((resp) => resp.data);


