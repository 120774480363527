import { FC, useState, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Box from "@mui/material/Box";
// import Slide from "@mui/material/Slide";
import { ThemificationProvider } from "./context/ThemeProvider";
import { UnitsDisplay } from "./containers/UnitsDisplay";
import { ReservationForm } from "./containers/ReservationForm";
import { IUnitsInformation } from "./components/UnitsInformation";
// import Typographies from "./TypographyReference";
import { UnitsInformationProvider } from "./context/UnitsInformationProvider";
import { useConfig } from "./context/ConfigProvider";
import { BugSnagErrorBoundary } from "./utils/BugSnag";
import { EVENTS } from "./constants";

export const Widget: FC = () => {
  const containerRef = useRef(null);
  const { widgetParams, triggerEvent } = useConfig();
  const [displayPage, setDisplayPage] = useState<string>("unitsTable");
  const [selectedUnit, setSelectedUnit] = useState<IUnitsInformation>();

  let content = (
    <UnitsDisplay
      onSelectedUnit={(selUnit) => {
        triggerEvent(EVENTS.ON_UNITGROUP_SELECT, { unitGroupId: selUnit.id });
        setSelectedUnit(selUnit);
        setDisplayPage("reservationForm");
      }}
    />
  );

  switch (displayPage) {
    case "unitsTable":
    default:
      content = (
        <UnitsDisplay
          onSelectedUnit={(selUnit) => {
            triggerEvent(EVENTS.ON_UNITGROUP_SELECT, {
              unitGroupId: selUnit.id,
            });
            setSelectedUnit(selUnit);
            setDisplayPage("reservationForm");
          }}
        />
      );
      break;
    case "reservationForm":
      content = (
        <ReservationForm
          selectedUnit={selectedUnit!}
          onBackClick={() => {
            setSelectedUnit(undefined);
            setDisplayPage("unitsTable");
          }}
        />
      );
      break;
  }
  return (
    <BugSnagErrorBoundary
      onError={(ev) => {
        ev.addMetadata("ut-widgetParams", widgetParams);
      }}
    >
      <Box ref={containerRef}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemificationProvider>
            <UnitsInformationProvider {...widgetParams}>
              {content}
            </UnitsInformationProvider>
          </ThemificationProvider>
        </LocalizationProvider>
      </Box>
    </BugSnagErrorBoundary>
  );
};

// export default Widget;
