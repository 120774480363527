import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import "@fontsource/montserrat";

const PRIMARY_COLOR = "#337AB7";
const SECONDARY_COLOR = "#F48400";

interface IThemeContext {
  theme: Theme;
  primaryColor: string;
  secondaryColor: string;
}

const defaultContext: IThemeContext = {
  theme: createTheme(),
  primaryColor: PRIMARY_COLOR,
  secondaryColor: SECONDARY_COLOR,
};

const ThemeContext = createContext(defaultContext);

export const useTheme = () => useContext(ThemeContext);

const useThemeProvider = (): IThemeContext => {
  const updatedTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: PRIMARY_COLOR,
          },
          secondary: {
            main: SECONDARY_COLOR,
          },
        },
        typography: {
          fontFamily: "Montserrat",
        },
      }),
    []
  );
  return {
    primaryColor: PRIMARY_COLOR,
    secondaryColor: SECONDARY_COLOR,
    theme: updatedTheme,
  };
};

export const ThemificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const providerValue = useThemeProvider();
  return (
    <ThemeContext.Provider value={providerValue}>
      <ThemeProvider theme={providerValue.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
