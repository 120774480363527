export const DATE_FORMAT = "MM/DD/YYYY";

export enum EVENTS {
    ON_DATA_LOAD = "ON_DATA_LOAD",
    
    ON_RESERVATION= "ON_RESERVATION",
    ON_RESERVATION_FORM_SUBMIT = "ON_RESERVATION_FORM_SUBMIT",
    ON_RESERVATION_FORM_VALIDATION_ERROR = "ON_RESERVATION_FORM_VALIDATION_ERROR",
    ON_RESERVATION_FORM_SERVER_ERROR = "ON_RESERVATION_FORM_SERVER_ERROR",

    ON_UNITGROUP_SELECT = "ON_UNITGROUP_SELECT",
    ON_UNITTIER_SELECT = "ON_UNITTIER_SELECT",

    ON_FILTERS_UPDATE = "ON_FILTERS_UPDATE",
}