import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface IInformationPairParams {
  data: Array<{ label: string; value: string }>;
  spaced?: boolean;
}

export const InformationPair: FC<IInformationPairParams> = ({
  data,
  spaced = false,
}) => {
  return (
    <Box>
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            my: 0.5,
            ...(spaced ? { justifyContent: "space-between" } : {}),
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {item.label}:
          </Typography>
          <Typography variant="body2" sx={{ ml: 0.5 }}>
            {item.value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
