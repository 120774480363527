import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

const BUG_SNAG_KEY = import.meta.env.VITE_BUGSNAG_API_KEY || "a4de2a4209e6e641c523cef960dd4744";
const BUG_SNAG_ENVIRONMENT = import.meta.env.VITE_BUGSNAG_ENVIRONMENT || "development";

export const BugSnagClient = Bugsnag.start({
  apiKey: BUG_SNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: BUG_SNAG_ENVIRONMENT,
});

export const BugSnagErrorBoundary =
  BugSnagClient.getPlugin("react")!.createErrorBoundary(React);

BugsnagPerformance.start({
  apiKey: BUG_SNAG_KEY,
  bugsnag: BugSnagClient,
});
