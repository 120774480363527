import { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IUnitsInformation } from "./type";
import { Pricing } from "./subComponents/Pricing";
import { UnitsImage } from "./subComponents/Image";
import { Amenities } from "./subComponents/Amenities";
import { useConfig } from "../../context/ConfigProvider";

/**
 * This component is responsible for rendering the Tablet view of the UnitsInformation.
 */
export const UnitsInformationTablet: FC<IUnitsInformation> = (props) => {
  const { configuration } = useConfig();
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "block",
          lg: "none",
          xl: "none",
        },
        p: 1,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="h5">
          {props.name || `${props.size.length}’ x ${props.size.width}’`}
        </Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            mb: 1,
            flexDirection: theme.direction === "ltr" ? "row" : "row-reverse",
          })}
        >
          <Box sx={{ flex: 1 }}>
            {configuration.showUnitCount &&
              props.availbleUnits <=
                configuration.contactForReserveUnitThreshold &&
              props.availbleUnits !== 0 && (
                <Typography
                  variant="caption"
                  sx={(theme) => ({ color: theme.palette.secondary.main })}
                >{`${props.availbleUnits} unit available`}</Typography>
              )}
            <Pricing {...props} />
            <Amenities
              amenities={props.amenities}
              noOfAmenitiesToShow={props.noOfAmenitiesToShow}
            />
          </Box>
          <UnitsImage
            unitInformation={props}
            heightFactor={12}
            widthFactor={16}
            forceCarousel
          />
        </Box>
        <Button fullWidth variant="contained" onClick={props.onSelectClick}>
          {props.availbleUnits <= configuration.contactForReserveUnitThreshold
            ? "Contact Us"
            : "Select"}
        </Button>
      </Box>
    </Box>
  );
};
