import {
  createContext,
  useContext,
  useState,
  FC,
  PropsWithChildren,
  useCallback,
} from "react";
import { IWidgetParams } from "../types/WidgetParams";
import { widgetParams } from "../mock/widgetParams";
import {
  IUTConfiguration,
  defaultUTConfiguration,
} from "../types/UTConfiguration";

const CUSTOM_EVENT_TRIGGER_NAME = "storable-webapp-ut-event";

type EventTriggerFn = (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventData: Record<string, any>
) => void;

type EventDispatcherFn = (customEvent: CustomEvent) => void;

interface IConfigContext {
  widgetParams: IWidgetParams;
  configuration: IUTConfiguration;
  refreshConfig: () => void;
  triggerEvent: EventTriggerFn;
}

const defaultConfigContext: IConfigContext = {
  widgetParams,
  configuration: defaultUTConfiguration,
  refreshConfig: () => {},
  triggerEvent: () => {},
};

const ConfigContext = createContext(defaultConfigContext);

export const useConfig = () => useContext(ConfigContext);

const useConfigProvider = (
  widgetParamsInfo: IWidgetParams,
  onEvent: EventDispatcherFn
): IConfigContext => {
  const [config, setConfig] = useState<IUTConfiguration>(
    defaultUTConfiguration
  );
  const fetchConfig = useCallback(() => {
    setConfig(defaultUTConfiguration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetParamsInfo.configId]);

  const triggerEvent: EventTriggerFn = (eventName, eventData) => {
    // console.log("Triggering Event", eventName, eventData);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).storableUSWidget?.onEvent?.({ event:eventName, data: eventData });
    onEvent(
      new CustomEvent(CUSTOM_EVENT_TRIGGER_NAME, {
        detail: { eventName, data: eventData },
        bubbles: true,
        composed: true,
      })
    );
  };

  return {
    widgetParams: { ...widgetParams, ...widgetParamsInfo },
    configuration: config,
    refreshConfig: fetchConfig,
    triggerEvent,
  };
};

export const ConfigProvider: FC<
  PropsWithChildren<Partial<IWidgetParams> & { onEvent: EventDispatcherFn }>
> = ({
  children,
  organizationId,
  facilityId,
  providerId,
  configId,
  onEvent,
}) => {
  const providerValue = useConfigProvider(
    {
      organizationId: organizationId || widgetParams.organizationId,
      facilityId: facilityId || widgetParams.facilityId,
      configId,
      providerId: providerId || widgetParams.providerId,
    },
    onEvent
  );
  return (
    <ConfigContext.Provider value={providerValue}>
      {children}
    </ConfigContext.Provider>
  );
};
